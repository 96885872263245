import classnames from "classnames";
import React from "react";
import GetText, { useString } from "../../i18n/GetText";
import FacebookIcon from "../../icons/FacebookIcon";
import LinkedInIcon from "../../icons/LinkedInIcon";
import TwitterIcon from "../../icons/TwitterIcon";
import { useDeploymentDetails } from "../../model/deployment";
import { useSocialConfigData } from "../../model/social";
import { useGatsbyLocation } from "../../utils/useGatsbyLocation";
import { usePageMetadata } from "../../utils/usePageMetadata";
import Link from "../Link/Link";
import Text, { TextVariant } from "../Text/Text";
import styles from "./SocialLinks.module.scss";

interface SocialLinksProps {
  className?: string;
  title: string;
  // The white version is larger and copes with a background image.
  type?: "normal" | "white";
  scale?: number;
  textVariant?: TextVariant;
}

const SocialLinks = ({
  title,
  className,
  type = "normal",
  textVariant = "default",
  scale = 1
}: SocialLinksProps) => {
  const { pathname: url } = useGatsbyLocation();
  const deployment = useDeploymentDetails();
  const { canonicalUrlOverride } = usePageMetadata();
  const encodedPageUrl = encodeURIComponent(
    `${deployment.url}${canonicalUrlOverride || url}`
  );
  const pageLinkTitle = encodeURIComponent(`${title} #microbit`);
  const twitterLink = `https://twitter.com/intent/tweet?text=${pageLinkTitle}&url=${encodedPageUrl}`;
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedPageUrl}`;
  const linkedInLink = `https://www.linkedin.com/shareArticle?url=${encodedPageUrl}`;
  const { twitter, facebook, linkedin } = useSocialConfigData();
  const shareText = useString("share", "Share");
  return (
    <div className={classnames(styles.root, className, styles[type])}>
      <Text className={styles.shareTitle} variant={textVariant} as="span">
        <GetText id="share" fallback="Share" />
      </Text>
      <Link
        aria-label={`Facebook ${shareText}`}
        to={facebookLink || `https://www.facebook.com/${facebook.handle}`}
      >
        <FacebookIcon height={20 * scale} width={20 * scale} />
      </Link>
      <Link
        aria-label={`X ${shareText}`}
        to={twitterLink || `https://twitter.com/${twitter.handle}`}
      >
        <TwitterIcon height={20 * scale} width={20 * scale} />
      </Link>
      <Link
        aria-label={`LinkedIn ${shareText}`}
        to={
          linkedInLink || `https://www.linkedin.com/company/${linkedin.handle}`
        }
      >
        <LinkedInIcon height={20 * scale} width={20 * scale} />
      </Link>
    </div>
  );
};

export default SocialLinks;
