import React, { ReactNode } from "react";
import { Wrapper, Col, Row } from "../../components/Grid";
import styles from "./TwoColumnLayout.module.scss";
import Aside from "../../components/Aside/Aside";
import CampaignRandom from "../../components/CampaignRandom/CampaignRandom";
import classnames from "classnames";
import { SanityCampaign } from "../../model/common";

export interface TwoColumnLayoutProps {
  topRows?: ReactNode;
  aside: ReactNode;
  main: ReactNode;
  campaigns: SanityCampaign[];
  className?: string;
}

export const TwoColumnLayout = ({
  aside,
  main,
  topRows,
  campaigns,
  className
}: TwoColumnLayoutProps) => {
  return (
    <Wrapper className={classnames(styles.container, className)}>
      <div className={styles.topRows}>{topRows}</div>
      <Row
        className={classnames(topRows && styles.addMargin, styles.row)}
        justify="center"
      >
        <Col
          xs={12}
          sm={10}
          md={2}
          as="aside"
          className={styles.narrowCampaign}
        >
          <Aside>
            {aside}
            <CampaignRandom campaigns={campaigns} orientation="vertical" />
          </Aside>
        </Col>
        <Col xs={12} sm={10} md={8} as="main">
          {main}
        </Col>
        <Col xs={12} sm={10} md={0} as="aside" className={styles.wideCampaign}>
          <CampaignRandom campaigns={campaigns} orientation="horizontal" />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default TwoColumnLayout;
