import React from "react";
import { useString } from "../../i18n/GetText";
import TwoColumnLayout from "../TwoColumnLayout/TwoColumnLayout";
import { SanityNewsArticle, SanityNewsArticleMeta } from "../../model/news";
import NewsLeftNav from "../../components/NewsLeftNav/NewsLeftNav";
import TitleRow from "../../components/TitleRow/TitleRow";
import IsDesktop from "../../components/IsDesktop/IsDesktop";
import ArticleContent from "../../components/ArticleContent/ArticleContent";
import { SanityCampaign } from "../../model/common";

export interface NewsNavLayoutProps {
  campaigns: SanityCampaign[];
  allMeta: SanityNewsArticleMeta[];
  article: SanityNewsArticle;
  /**
   * 4 most recent, newest first. We should show max 3.
   * Might need to exclude the current article.
   */
  recent: SanityNewsArticle[];
}

export const NewsNavLayout = ({
  campaigns,
  article,
  allMeta,
  recent: recentMaybeIncludingCurrent
}: NewsNavLayoutProps) => {
  const h1 = useString("news");
  const recent = recentMaybeIncludingCurrent
    .filter(x => x._id !== article._id)
    .slice(0, 3);
  return (
    <TwoColumnLayout
      campaigns={campaigns}
      topRows={<TitleRow title={h1} socialTitle={article.title} />}
      aside={
        <IsDesktop>
          <nav>
            <NewsLeftNav active={article} entries={allMeta} />
          </nav>
        </IsDesktop>
      }
      main={
        <ArticleContent
          article={article}
          related={recent}
          relatedTitleId="latest-stories"
        />
      }
    />
  );
};

export default NewsNavLayout;
