import React, { ReactNode } from "react";
import { Row, Col } from "../Grid";
import SocialLinks from "../SocialLinks/SocialLinks";
import styles from "./TitleRow.module.scss";
import classnames from "classnames";
import Text from "../Text/Text";

interface TitleRowProps {
  title: string;
  /**
   * We show social information by default.
   */
  social?: boolean;
  /**
   * Override title for social sharing.
   */
  socialTitle?: string;

  /**
   * Additional row children.
   */
  children?: ReactNode;

  className?: string;
  xs?: number | "auto" | undefined;
  sm?: number | "auto" | undefined;
  md?: number | "auto" | undefined;
  ariaHiddenTitle?: boolean;
}

const TitleRow = ({
  title,
  ariaHiddenTitle = false,
  socialTitle,
  social = true,
  className,
  xs,
  sm = 10,
  md
}: TitleRowProps) => (
  <Row className={classnames(styles.root, className)} justify="center">
    <Col xs={xs} sm={sm} md={md}>
      <Text aria-hidden={ariaHiddenTitle && true} variant="h1">
        {title}
      </Text>
      {social && <SocialLinks title={socialTitle || title} />}
    </Col>
  </Row>
);

export default TitleRow;
