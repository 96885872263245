import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import NewsNavLayout from "../layouts/NewsNavLayout/NewsNavLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityCampaign } from "../model/common";
import { GlobalMenus } from "../model/menu";
import {
  SanityNewsArticle,
  SanityNewsArticleMeta,
  SanityNewsPage
} from "../model/news";
import { urlForDocument } from "../urls";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query NewsPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityNewsPage(_id: { eq: $_id }) {
      _id
      _type
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
    allMeta: allSanityNewsArticle(sort: { fields: date, order: DESC }) {
      nodes {
        _id
        _type
        title
        date
        slug {
          current
        }
      }
    }
    latestArticles: allSanityNewsArticle(
      sort: { fields: date, order: DESC }
      limit: 1
    ) {
      nodes {
        _id
        _type
        title
        date
        slug {
          current
        }
        _rawContent(resolveReferences: { maxDepth: 7 })
      }
    }
    recent: allSanityNewsArticle(
      sort: { fields: date, order: DESC }
      limit: 4
    ) {
      nodes {
        _id
        _type
        title
        date
        slug {
          current
        }
        image {
          ...SanityImage
        }
      }
    }
    campaigns: allSanityCampaign(filter: { activeSiteAreas: { in: "news" } }) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const NewsPage = ({
  pageContext,
  data: { menus, page, latestArticles, campaigns, allMeta, recent },
  location
}: NewsPageProps) => {
  const { title: titlePrefix } = page;
  const latestArticle = latestArticles.nodes[0];
  const title = `${titlePrefix} - ${latestArticle.title}`;
  return (
    <PageLayout
      siteArea={SiteArea.NEWS}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates,
        // We want to share the article the user was viewing, not the news page itself.
        canonicalUrlOverride: urlForDocument(latestArticle)
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <NewsNavLayout
        campaigns={campaigns.nodes}
        article={latestArticle}
        allMeta={allMeta.nodes}
        recent={recent.nodes}
      />
    </PageLayout>
  );
};

export default NewsPage;

interface NewsPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityNewsPage;
    latestArticles: { nodes: SanityNewsArticle[] };
    allMeta: { nodes: SanityNewsArticleMeta[] };
    recent: { nodes: SanityNewsArticle[] };
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
