import React, { useState } from "react";
import styles from "./NewsLeftNav.module.scss";
import { SanityNewsArticleMeta } from "../../model/news";
import { urlForDocument } from "../../urls";
import Link from "../Link/Link";
import groupBy from "lodash.groupby";
import sortBy from "lodash.sortby";
import GetText from "../../i18n/GetText";
import PlainButton from "../Button/PlainButton/PlainButton";
import Text from "../Text/Text";
import Divider from "../Divider/Divider";

interface NewsLeftNavProps {
  active: SanityNewsArticleMeta;
  entries: SanityNewsArticleMeta[];
}

interface Month {
  name: string;
  articles: SanityNewsArticleMeta[];
}

interface Year {
  name: string;
  months: Month[];
}

interface Selection {
  year?: string;
  month?: string;
}

const NewsLeftNav = ({ active: initialActive, entries }: NewsLeftNavProps) => {
  const [active, setActive] = useState<Selection>({
    year: isoYear(initialActive.date),
    month: isoMonth(initialActive.date)
  });
  const toggleActive = (clicked: Selection) => {
    setActive({
      year:
        active.year === clicked.year && !clicked.month
          ? undefined
          : clicked.year,
      month: active.month === clicked.month ? undefined : clicked.month
    });
  };
  const byYear = groupBy(entries, article => isoYear(article.date));
  const yearsInReverse = reverseNumericSort(Object.keys(byYear));
  const yearTree: Year[] = yearsInReverse.map(year => {
    const yearArticles = byYear[year];
    const byMonth = groupBy(yearArticles, article => isoMonth(article.date));
    const monthsInReverse = reverseNumericSort(Object.keys(byMonth));
    return {
      name: year,
      months: monthsInReverse.map(month => ({
        articles: byMonth[month],
        name: month
      }))
    };
  });
  return (
    <ul className={styles.yearList}>
      {yearTree.map((year, index: number) => (
        <React.Fragment key={year.name}>
          {index > 0 && <Divider className={styles.divider} />}
          <li>
            <PlainButton
              onClick={() => {
                toggleActive({ year: year.name });
              }}
              style={{ padding: 0 }}
            >
              <Text variant="defaultBold">{year.name}</Text>
            </PlainButton>
            {year.name === active.year && (
              <ul className={styles.monthList}>
                {year.months.map(month => (
                  <li key={month.name}>
                    <PlainButton
                      /* Temporary to avoid style conflict. */
                      style={{ padding: 0 }}
                      onClick={() =>
                        toggleActive({ year: year.name, month: month.name })
                      }
                    >
                      <Text variant="defaultBold">
                        <GetText id={`month-${month.name}`} />
                      </Text>
                    </PlainButton>
                    {month.name === active.month && (
                      <ul className={styles.articleList}>
                        {month.articles.map(article => (
                          <li key={article._id}>
                            <Link
                              className={
                                article._id === initialActive._id
                                  ? styles.activeLink
                                  : styles.inactiveLink
                              }
                              to={urlForDocument(article)}
                            >
                              <Text variant="defaultLight">
                                {article.title}
                              </Text>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
};

const isoYear = (date: string): string => date.split("-")[0];
const isoMonth = (date: string): string => date.split("-")[1];
const reverseNumericSort = (values: string[]) =>
  sortBy(values, v => -parseInt(v, 10));

export default NewsLeftNav;
