import React from "react";
import { ArticleContentFields } from "../../model/common";
import { ArticleCardFields } from "../ArticleCard/ArticleCard";
import ArticleTagInfo from "../ArticleTagInfo/ArticleTagInfo";
import BlockContent from "../BlockContent/BlockContent";
import Card from "../Card/Card";
import RelatedArticles from "../RelatedArticles/RelatedArticles";
import Text, { TextVariant } from "../Text/Text";

interface ArticleContentProps {
  article: ArticleContentFields;
  titleVariant?: TextVariant;
  related?: ArticleCardFields[];
  relatedTitleId: string;
}

const ArticleContent = ({
  article,
  titleVariant = "h2",
  related,
  relatedTitleId
}: ArticleContentProps) => {
  return (
    <Card type="main">
      <BlockContent
        cap="3/4"
        highlightFirstParagraph
        before={
          <>
            <Text variant={titleVariant}>{article.title}</Text>
            <ArticleTagInfo article={article} />
          </>
        }
        content={article._rawContent}
        after={
          <RelatedArticles titleId={relatedTitleId} articles={related || []} />
        }
      />
    </Card>
  );
};

export default ArticleContent;
