import React from "react";
import GetText from "../../i18n/GetText";
import CardTrio from "../CardTrio/CardTrio";
import ArticleCard, { ArticleCardFields } from "../ArticleCard/ArticleCard";
import Text from "../Text/Text";

interface RelatedArticlesProps {
  articles: ArticleCardFields[];
  titleId: string;
}

// tslint:disable-next-line: function-name
function RelatedArticles({ articles, titleId }: RelatedArticlesProps) {
  // Exclude drafts etc that are missing images.
  articles = articles.filter(a => a.image);
  if (articles.length === 0) {
    return null;
  }
  return (
    <>
      <Text variant="h2">
        <GetText id={titleId} />
      </Text>
      <CardTrio>
        {articles.map(article => (
          <ArticleCard article={article} />
        ))}
      </CardTrio>
    </>
  );
}

export default RelatedArticles;
